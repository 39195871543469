













































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import Papa, { ParseResult } from 'papaparse';

@Component
export default class Importer extends Vue {
  @Prop({ required: true, type: Array })
  readonly fields!: string[];

  @Prop({ default: false })
  readonly fieldNames!: string[] | false;

  @Prop({ required: true, type: Function })
  readonly callback!: (data: any[]) => Promise<void>;

  data: any[][] = [];
  mapped: any[] = [];

  private hasHeaders: boolean = true;
  private file: File | null = null;
  private error: any = null;
  private loading: boolean = false;

  $refs: {
    csvInput: HTMLInputElement;
  };

  async loadCsv(): Promise<void> {
    this.file = null;
    if (!this.$refs.csvInput.files || this.$refs.csvInput.files.length === 0)
      return;

    try {
      this.file = this.$refs.csvInput.files[0];
      const result = await this.parseCsv(this.file);
      this.data = result.data;
    } catch (e) {
      this.file = null;
    }
  }

  changedMapping(idx: number): void {
    const selected = this.mapped[idx];
    if (!selected) {
      return;
    }

    for (let i = 0; i < this.mapped.length; i++) {
      if (i !== idx && this.mapped[i] === selected) {
        this.mapped[i] = null;
      }
    }
  }

  async doImport(): Promise<void> {
    try {
      this.loading = true;
      await this.callback(this.transformObjects());
    } catch (e) {
      this.error = e;
    } finally {
      this.loading = false;
    }
  }

  transformObjects(): any[] {
    const mapping = Object.entries(this.mapped).filter(
      ([, name]) => name !== null,
    );

    return this.dataRows.map((row) => {
      const res = {};
      for (const [idx, name] of mapping) {
        res[name] = row[idx];
      }
      return res;
    });
  }

  get dataRows(): any[][] {
    return this.data.slice(+this.hasHeaders);
  }

  get hasData(): boolean {
    return this.data.length > +this.hasHeaders;
  }

  parseCsv(file: File): Promise<ParseResult<any>> {
    return new Promise((resolve, reject) => {
      Papa.parse<any, File>(file, {
        skipEmptyLines: true,
        dynamicTyping: true,
        complete(results: ParseResult<any>): void {
          resolve(results);
        },
        error(error: Error): void {
          reject(error);
        },
      });
    });
  }
}
