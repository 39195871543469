










import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Importer } from '@/components/csvimport';

import {
  CUSTOMER_IMPORT_PROPERTIES,
  CUSTOMER_IMPORT_TRANSLATIONS,
} from '@/models';
import { CustomerService } from '@/lib/services';

@Component({
  components: {
    Importer,
  },
})
export default class CustomerImportPage extends Vue {
  importProperties: string[] = CUSTOMER_IMPORT_PROPERTIES;
  translatedProperties: any = false;

  mounted(): void {
    this.translatedProperties = CUSTOMER_IMPORT_TRANSLATIONS.map((e) =>
      this.$i18n.tc(`customer.${e}`),
    );
  }

  async callback(objects: any[]): Promise<void> {
    const response = await CustomerService.bulkImport(objects);
    this.$toaster.success(
      this.$i18n.tc('messages.success.import.customers', response.count),
    );
    await this.$router.push({ name: 'customers' });
  }
}
